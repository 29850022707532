import DropDown from '../DropDown'
import _ from 'lodash';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip} from '@mui/material';


const PRODUCTS = {
    guard: 'guard', 
    drip: 'drip', 
    comfort: 'comfort',
    presence: 'presence'
    }
    
    const products = [
    {
        label: _.capitalize(PRODUCTS.guard),
        value: PRODUCTS.guard,
    },
    {
        label: _.capitalize(PRODUCTS.drip),
        value: PRODUCTS.drip
    }, 
    {
        label: _.capitalize(PRODUCTS.comfort),
        value: PRODUCTS.comfort
    }, 
    {
        label: _.capitalize(PRODUCTS.presence),
        value: PRODUCTS.presence
    }
];


const Products = (props) => {

    const handleChosenProduct = (event) => {
        props.setProduct(event.target.value);
        props.setProfile(undefined);
        props.setReportInterval(undefined)
        props.setSpreadingFactor(undefined);
        props.setRatio(5);
    }

    return(
        <>
            <header className='App'>
                <span>Choose option&nbsp;</span>
                <Tooltip title='Please select which Strips you are interested in. You can find more information about the different products on our website (https://sensative.com/sensors/strips-sensors-for-lorawan/). If you have any technical questions you can contact us here: https://sensative.com/contact/'>
                <InfoOutlinedIcon
                    fontSize="small"
                    color="primary"  
                />
                </Tooltip>  
            </header> 

            <DropDown
                onChange={handleChosenProduct}
                value={props.product}
                options={products}
                label={'Products'}
            />
        </>
    );
}

export default Products;
