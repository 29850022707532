import { FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import _ from 'lodash';

const DropDown = (props) => {

    return(
    <>
    <div>  
    <FormControl fullWidth>
        <InputLabel sx={{ color: '#01676c' }}
            id="demo-simple-select-label">{ props.label }</InputLabel>
        <Select
            style={{
                width: "300px",
                marginBottom: "20px",
                /*fontSize: "20px"*/
            }}
            value={ props.value }
            label={ props.label }
            onChange={ props.onChange }
            
            // Nedan följer tappert försök att ändra färg på linjen runt drop-downen <3 
            sx={{
                borderColor: 'primary.main',
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                    borderWidth: '0.15rem',
                },
                "&:after": {
                    borderBottomColor: "primary.main",
                },
                "& .MuiSvgIcon-root": {
                    color: "primary.main",
                },
                ".MuiSelect-select": {
                    color: "primary.main"
                },
            }}
        >
            {_.map(_.values(props.options), (option) => (
                <MenuItem value={option.value}> {option.label}</MenuItem>
            ))}
 
        </Select>
    </FormControl>
    </div>
    </>
    );  
};

export default DropDown;