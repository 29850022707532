import { 
    stripsProducts, 
    regionsOptions, 
    baseIntervals, 
    pollConsumptions_uA, 
    pollDurations_ms, 
    baseReports_per_h,
    spreadingFactorOptions,
} from "./constants.js"

const calculateTime = (
    selectedProduct, 
    selectedProfile, 
    selectedRegion, 
    selectedSpreadingFactor, 
    selectedResendRatio, 
    selectedReportInterval
    ) => {

    console.log('selected product:', selectedProduct);    
    console.log('selected profile:', selectedProfile);
    console.log('selected spreading factor: ', selectedSpreadingFactor);
    console.log('selected resend ratio: ', selectedResendRatio);
    console.log('selected report interval: ', selectedReportInterval);

    // Funktionen nedan är gjord av Lars Mats, Inte Alexandra. Tacka/Skyll på honom!!

    const calculatePollingPowerConsumption_uA = (intervals) => {

        let consumptionSum_uA = 0;
        const allIntervals = {...baseIntervals, ...intervals}; 
    
        Object.keys(allIntervals).map(key => {
        const interval_s = allIntervals[key];
        const consumption_uA = pollConsumptions_uA[key];
        const duration_ms = pollDurations_ms[key];
        if (! (interval_s && consumption_uA && duration_ms))
            throw new Error("There is a value missing for " + key); //det är här & raden under som koden smäller om HUMIDITY_POLL = 0
        const eventsPerHour = 3600.0/interval_s;
        const thisConsumption_uA = consumption_uA*duration_ms/3.6E6 /* ms/H */ * eventsPerHour;
        consumptionSum_uA += thisConsumption_uA;
        //console.log(key + ": Polling comsuption uA: " + thisConsumption_uA);
        });
        return consumptionSum_uA;
    }

    const calculateEventTransmission_uAh = (key) => {
        let eventTransmission_ = 0;
        const consumption_uA = spreadingFactorOptions[key].consumption_uA;
        const duration_ms = spreadingFactorOptions[key].duration_ms;
        if (! (consumption_uA && duration_ms))
          throw new Error("There is a value missing for " + key);
        eventTransmission_ = (consumption_uA*duration_ms)
        //console.log(key + ": SF comsuption uA: " + eventTransmission_);
        return eventTransmission_/3.6e3;
    }

      const calculateReportConsumption_uA = (selectedReportInterval, dataRate, reports) => {

        const consumptionPerEvent_uAh = calculateEventTransmission_uAh(dataRate);
        let consumptionSum_uA = 0;
        let allReports;

        //console.log('---------selectedReportInterval: ' + selectedReportInterval)
  
        if (selectedReportInterval) {
            allReports = {
                ...baseReports_per_h,
                actualReport: selectedReportInterval
            };
        }
        else{
            allReports = {
                ...baseReports_per_h,
                ...reports
            };
        }

        Object.keys(allReports).map(key => {
            const reports_per_h = allReports[key];
            const consumption_uAh = consumptionPerEvent_uAh;

            if (! (reports_per_h && consumption_uAh))
                throw new Error("There is a value missing for " + key + " or  dataRate" + dataRate); 
            let thisConsumption_uA = consumption_uAh*reports_per_h;
            /*
            if( thisConsumption_uA == Infinity){
                thisConsumption_uA = 1.0/24.0; //reverts to default value from constants.js
                console.log(thisConsumption_uA);
            }*/
            consumptionSum_uA += thisConsumption_uA;
            //console.log(key + ": Report comsuption uA: " + thisConsumption_uA);

        });
        return consumptionSum_uA;  
    }


    const calculateProfile = () => {
        if (selectedProduct && selectedProfile){
            const result = calculatePollingPowerConsumption_uA(stripsProducts[selectedProduct][selectedProfile].intervals);
            //console.log("ProfileResult: " + result)
            return result;
        }
    };

    const calculateWithReport = () => {
        if (selectedSpreadingFactor && selectedProduct && selectedProfile){ 
            const result = calculateReportConsumption_uA(selectedReportInterval, selectedSpreadingFactor, stripsProducts[selectedProduct][selectedProfile].reports_per_h);
            //console.log("Report result: " + result);
            return result;
        }
    }

    const calculateWithResendRatio = () => {
        const result = selectedResendRatio*0.01;
        //console.log("Resend Ratio result: " + result);
        return 1 + result;
    }

    const calculateWithRegion = () => {         //still not used. Constants found in constants.js are made up for testing purpouses - do not use!
        if (selectedRegion) {
            const result = regionsOptions[selectedRegion].default;
            //console.log("profile result: " + result);
            return result;
        }
    } 

    const selfDischargeOfBattery = 0.822;
    const sensorInSleep = 0.984;

    /*
    const profileResult = calculateProfile();
    console.log('...profileResult: ' + profileResult)
    const reportResult = calculateWithReport();
    console.log('...reportResult: ' + reportResult);
    const resendRatio = calculateWithResendRatio();
    console.log('...reportResendRatio: ' + resendRatio);
    */

    const result_uA = (((
        selfDischargeOfBattery + 
        sensorInSleep + 
        calculateProfile() + 
        calculateWithReport()) *      
        calculateWithResendRatio())
    );
  
    console.log("final result: " + result_uA + ' uA');

    const batteryCapacity_uAh = 480000;
    const longevity_h = batteryCapacity_uAh / result_uA;
    const longevity_years = longevity_h / 24 / 365;
    //console.log("Hours: " + longevity_h);
    //console.log("Years: " + longevity_years);
    console.log('-----------------------------');
    return longevity_h; 
}

export default calculateTime;