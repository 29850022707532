// SPF 7, 8, 9, 10, 11 (7 svagast, drar minst el, 11 starkast, når längst, störst sannolikhet att det når fram, drar mer el)
import React from 'react';
import Button from '@mui/material/Button';
import { ButtonGroup, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {spreadingFactorOptions} from '../constants';
import _ from 'lodash';
import {useEffect} from 'react'

function setNewBackgroundColor() {
  console.log('This is where the background changes color');

}

const SpreadingFactor = (props) => {

  const [selectedBtn, setSelectedBtn] = React.useState(-1);
  useEffect(() => {
  }, [selectedBtn]);

  const handleClick = (selectedSpreadingFactor, key) => {
    props.setValue(key);
    setSelectedBtn(key);
  }

  return (
  <>
  <div>
    <Header
      toolTipTitle={props.toolTipTitle}
    />
    <ButtonGroup 
      style=
      {{
        borderRadius: 35,
        backgroundColor: "#01676c",
        padding: "15px",
        fontSize: "18px",
        marginBottom: "15px",
        color: "white"
      }}
      variant="number" 
      color="primary"  // this does nothing.........
      size="large"    // nor this <3
      aria-label="text button group"
      >

      {_.map(spreadingFactorOptions, (option, key) => (
        <Button
          style=
          {{
            backgroundColor: selectedBtn === key ? "#ffffff26" : "#01676c",
            borderRadius: 35
          }}
          color={selectedBtn === key ? "primary" : "secondary"}
          onClick= {(event) => {handleClick(event.target.value, key)}}
        >
          {option.label}
        </Button>
      ))} 
    </ButtonGroup>
  </div>  
  </>
  );
}

        /*onClick=
          {event => props.setValue(key)} */
const Header = (props) => {
  return(
    <>
    <header className='App'>
      <span>Spreading factor&nbsp;</span>
      <Tooltip 
        title={props.toolTipTitle}>
      <InfoOutlinedIcon
        fontSize="small"
        color="primary"  
      />
      </Tooltip>  
    </header> 
    </>
  )
};

export default SpreadingFactor;