import DropDown from './DropDown'
import _ from 'lodash';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

const ProfileSelector = (props) => {

    const handleSetValue = (event) => {
      props.setValue(event.target.value);
      props.setReportInterval(undefined)
      props.setSpreadingFactor(undefined);
    }

  return(
    <>
    <Header
      toolTipTitle={props.toolTipTitle} 
    />
    <DropDown
      onChange={handleSetValue}
      value={props.value}
      options={props.options}
      label={props.label}
    />
    </>
  );
}
const Header = (props) => {
  return(
    <header className='App'>
      <span>Choose option&nbsp;</span>
      <Tooltip 
        title={props.toolTipTitle}>
      <InfoOutlinedIcon
        fontSize="small"
        color="primary"  
      />
      </Tooltip>  
    </header> 
  )
};

export default ProfileSelector;