import { useState } from 'react';
import logo from './sensative-icon-inverse.brg.svg';
import './App.css';
import ReportInterval from './components/ReportInterval.js';
import SpreadingFactor from './components/SpreadingFactor';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DisplayTime from './components/DisplayTime';
import { Box } from '@mui/material';
import ResendRatio from './components/ResendRatio';
import _ from 'lodash'
import Products from './components/DropDownOptions/Products';
import ProfileSelector from './components/ProfileSelector';
import { stripsProducts, regionsOptions } from './constants';
import InfoBox from './components/InfoBox';
import InputField from './components/ToBeReleased.js/InputField';


const theme = createTheme({
  palette: {
    primary: {
      light: '#8fbcbe',
      main: '#01676c',
      dark: '#ffffff',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#000000',
      main: '#F7A93F',
      dark: '#000000',
      contrastText: '#000000',
    },
  }
});

function App() {

  const [product, setProduct] = useState();
  const [profile, setProfile] = useState();
  const [region, setRegion] = useState();
  const [spreadingFactor, setSpreadingFactor] = useState();
  const [ratio, setRatio] = useState(5)

  const [reportInterval, setReportInterval] = useState();
  const handleReportInterval = newReportInterval => {
    setReportInterval(newReportInterval);
  };

  return (
  <ThemeProvider theme={theme}>
         
    <div className='App-header'>
      <img src={logo} className="App-logo" alt="logo"/>
    </div>  
    <div className='Title'>
      Strips Battery Calculator
    </div>

    <div className='Display-time'>
      <Box 
        style=
        {{
          backgroundColor: spreadingFactor ? "#F7A93F" : "#ffffff26",
        }}
        //bgcolor='secondary.main'
        borderRadius={'5px'}
        padding={'10px'}
      >
        <DisplayTime 
          selectedReportInterval={reportInterval}
          selectedSpreadingFactor={spreadingFactor}
          selectedProduct={product}
          selectedProfile={profile}
          selectedRegion={region}
          selectedResendRatio={ratio}
        />
      </Box>
    </div>  

    <div className='Components'>

    <InfoBox/>

    <Products
      product={product}
      setProduct={setProduct}
      setProfile={setProfile}
      setReportInterval={setReportInterval}
      setRatio={setRatio}
      setSpreadingFactor={setSpreadingFactor}
    /> 
    
      {product === 'guard' && ( 
        <ProfileSelector
          toolTipTitle={'Please choose the Strips profile you want to use. A "profile" is configurations that have been verified in the lab for the selected device model. A Strips sensors can have multiple profiles with different settings that decides how often the sensor will create measurements, reports etc. You can find more information about the different standard Strips LoRaWAN profiles that are available here: https://strips-lora-config-app.service.sensative.net/profiles'}
          label={'Profile'}
          value={profile}
          setValue={setProfile}
          options={stripsProducts.guard}
          setReportInterval={setReportInterval}
          setSpreadingFactor={setSpreadingFactor}
        />
      )}
      {product === 'drip' && (
        <ProfileSelector
          toolTipTitle={'Please choose the Strips profile you want to use. A "profile" is configurations that have been verified in the lab for the selected device model. A Strips sensors can have multiple profiles with different settings that decides how often the sensor will create measurements, reports etc. You can find more information about the different standard Strips LoRaWAN profiles that are available here: https://strips-lora-config-app.service.sensative.net/profiles'}
          label={'Profile'}
          value={profile}
          setValue={setProfile}
          options={stripsProducts.drip}
          setReportInterval={setReportInterval}
          setSpreadingFactor={setSpreadingFactor}
        />
      )}
      {product === 'comfort' && (
        <ProfileSelector
          toolTipTitle={'Please choose the Strips profile you want to use. A "profile" is configurations that have been verified in the lab for the selected device model. A Strips sensors can have multiple profiles with different settings that decides how often the sensor will create measurements, reports etc. You can find more information about the different standard Strips LoRaWAN profiles that are available here: https://strips-lora-config-app.service.sensative.net/profiles'}
          label={'Profile'}
          value={profile}
          setValue={setProfile}
          options={stripsProducts.comfort}
          setReportInterval={setReportInterval}
          setSpreadingFactor={setSpreadingFactor}
        />
      )}
      {product === 'presence' && (
        <ProfileSelector
          toolTipTitle={'Please choose the Strips profile you want to use. A "profile" is configurations that have been verified in the lab for the selected device model. A Strips sensors can have multiple profiles with different settings that decides how often the sensor will create measurements, reports etc. You can find more information about the different standard Strips LoRaWAN profiles that are available here: https://strips-lora-config-app.service.sensative.net/profiles'}
          label={'Profile'}
          value={profile}
          setValue={setProfile}
          options={stripsProducts.presence}
          setReportInterval={setReportInterval}
          setSpreadingFactor={setSpreadingFactor}
        />
      )}
      {profile === 'periodic' && (
        <ReportInterval 
          onTimeChanged={handleReportInterval}
          setValue={setReportInterval}
        />
      )}
      {profile === 'doorCounter' && (
        <ReportInterval 
          onTimeChanged={handleReportInterval}
          setValue={setReportInterval}
          selectedSpreadingFactor={setSpreadingFactor}
        />
      )}
      {profile === 'averageTemp' && (
        <ReportInterval 
          onTimeChanged={handleReportInterval}
          setValue={setReportInterval}
        />
      )}
      {profile === 'coldStorage' && (
        <ReportInterval 
          onTimeChanged={handleReportInterval}
          setValue={setReportInterval}
        />
      )}
      
      {profile && (
        <SpreadingFactor
          toolTipTitle={"Spreading Factor is a key characteristic in LoRaWAN that can make or break your IoT solution. Finding the right Spreading Factor is crucial for realizing long-term performance of a LoRaWAN device. This article explains how to find the right balance between battery performance and long-range communication. Read more here: https://www.thethingsnetwork.org/article/how-spreading-factor-affects-lorawan-device-battery-life"}
          value={spreadingFactor}
          setValue={setSpreadingFactor}
        />
      )}

      {spreadingFactor && (
        <>
          <ResendRatio
            value={ratio}
            setValue={setRatio}
          />
          {/* <ProfileSelector
            toolTipTitle={'How we measure battery life spans differ between regions'}
            label={'Region'}
            value={region}
            setValue={setRegion}
            options={regionsOptions}
          /> */}
          {/* <InputField/> */}
        </>
      )}
    </div>

  </ThemeProvider> 
  );
}

export default App;
