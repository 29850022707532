/*
Avrundar till närmsta år
Det är så pass svårt att räkna ut batteritid pga olika oklara faktorer, 
så om en uträkning visar mer än 10 år, bör koden bara ut 10+ år - vilket just nu är utkommenterat
*/
import calculateTime from '../calculateTime.js';

function chooseCorrectGrammar(time_h){

    let time_y = Math.round(time_h * 0.000114 * 10) / 10;  // js är en tönt och det är såhär man gör att den avrundar till 1 decimalpunkt...

    // if (time_y > 10) {
    //     return ('10+ years')
    // }
    if (time_y < 1) {
        return ('Less than 1 year')
    }
    if (time_y === 1){
        return ("1 year");
    }
    if (time_y > 10){
        return (Math.floor(time_y) + ' years');
    }
    if (Math.floor(time_y%(Math.floor(time_y))*12) === 1){
        return (Math.floor(time_y) + " years and " + Math.floor(time_y%(Math.floor(time_y))*12) + ' month');
    }
    else{
        return (Math.floor(time_y) + " years and " + Math.floor(time_y%(Math.floor(time_y))*12) + ' months');
    }
}

const validateCalculationTime = (time) => {
    if (!time || isNaN(time)) {
        return "The battery's life span will be displayed here";
    }
    return chooseCorrectGrammar(time);
}

const DisplayTime = (props) => {

    const time_h = calculateTime(
        props.selectedProduct, 
        props.selectedProfile, 
        props.selectedRegion, 
        props.selectedSpreadingFactor, 
        props.selectedResendRatio,
        props.selectedReportInterval
    )

    return (
        <>
        <div class="box">
            {
                validateCalculationTime(time_h)
            }
        </div>
        </>
    )
};

export default DisplayTime;