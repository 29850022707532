

const InfoBox = () => {
    return(
        <>
            <p>This is only an estimation of the battery's lifespan. <br />
            The higher the estimation the larger the chance that the estimation may be faulty. <br />
            This is due to factors that are hard to control and calculate.
            </p>
        </>
    )
}

export default InfoBox;